import React, { useState } from "react";
import { Row, Col, Modal} from "react-bootstrap";
import Button from "../buttons/button";
import "./suscribe_talks.css";
import { apiRequest } from "../../apiRquest/apiRequest";
import validator from "validator";

const SuscribeTalks = ({
  ClassNameDisplay,
  ClassNameInput,
  ClassNameInput2,
  ButtonStyle,
  IDCampaignAction
}) => {
    const [email, setEmail] = useState("");
    const [tematica, setTematica] = useState("");
    const [emailError, setEmailError] = useState("");
    const [statusMsg, setStatusMsg] = useState("");
    const [successResponse, setSuccessResponse] = useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [evtBtnEnable, setEvtBtnEnable] = React.useState(true);

  const submit = (email, tematica) => {
    if (validator.isEmail(email)) {
     // alert("EMAIL & TEMATICA: " + email + " " +tematica);
        setEvtBtnEnable(false)
      apiRequest.post("/v1/contacts/send", {idCampaignAction: IDCampaignAction, newsletter: "true", email_personal: email, note: tematica })
          .then(() => {
            //alert("gracias por suscribirte!!")
          setSuccessResponse(true);
            setStatusMsg('Los datos se han enviado correctamente.<br /><strong>Gracias por suscribirte.</strong>');
            setModalShow(true);
            setEmail('');
            setTematica('')
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'newsletter_suscription'
            });
              setEvtBtnEnable(true)
          }).catch(err => {
            // console.log(err);
            setSuccessResponse(false);
            setStatusMsg('Hubo un error de conexión.<br />Por favor intenta mas tarde.');
            setModalShow(true);
            //alert("hubo un error al suscribirte intenta mas tarde")
          })
      setEmailError("");
    } else {
      setEmailError("Ingrese un Email válido");
    }
  };
    return (
    <>
        <Row className={`${ClassNameDisplay} suscribe-talks align-content-start align-items-md-end`}>
            <Col xs={12} md={6} className="mb-5 mb-md-0">
                <div className="inner-1 d-flex flex-column justify-content-start">
                    <p className="volanta"><span className="secondary-color">
                        SUSCRIBITE
                        </span>
                    </p>
                    <p className="texto mb-2 primary-color">
                        ¿Querés recibir recomendaciones<br />
                        e invitaciones a eventos?
                    </p>
                    <input
                        className={`${ClassNameInput} py-1 mb-1 mt-4`}
                        type="email"
                        value={email}
                        placeholder="Ingresá tu mail"
                        onChange={(e) => setEmail(e.target.value)}
            style={{ color: "black" }}
                    />
                </div>

        <div
            style={{
                fontWeight: "300",
                color: "red",
                position: "absolute",
                fontSize: "80%"
            }}
        >
          {emailError}
        </div>
            </Col>
            <Col xs={12} md={6}>
                <div className="inner-2 d-flex flex-column justify-content-start">
                    <p className="texto mb-2 primary-color">
            ¿De qué temática querés que sea
            <br />
                        nuestro próximo evento?
                    </p>
                    <input
                        className={`${ClassNameInput2} py-1 mb-1 mt-4`}
                        type="text"
                        value={tematica}
                        placeholder="Dejanos tu sugerencia (opcional)"
                        onChange={(e) => setTematica(e.target.value)}
                        style={{ color: "black" }}
                    />
                </div>

            </Col>
            <Col xs={12}>
                <div className="inner-1 my-5 text-left text-md-center">
                {
                    evtBtnEnable ? (
                   <Button
                     variant={ButtonStyle}
                     text="Enviar"
                     onClick={() => submit(email, tematica)}
                   />
                   ):(
                    <Button
                        variant={"disabled"}
                        text="Enviando..."
                        onClick={() => {
                            return false
                        }}
                        disabled={"disabled"}
                    />
                   )
                }
                </div>
            </Col>
        </Row>
        <Modal className="formmodal" show={modalShow} onHide={() => setModalShow(false)}
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{successResponse ? 'Muchas gracias': 'Atención'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={ {__html:statusMsg} } />
            </Modal.Body>
        </Modal>
    </>
    );
};

export default SuscribeTalks;
