import React, {useEffect, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Button from '../components/buttons/button'
import IframeVIdeo from "../components/iframe_video/iframe_video";
import "./live.css";
import Banner from "../imgs/bg_eventos.jpg";
import SuscribeTalks from "../components/suscribe_talks/suscribe_talks";
import { apiRequest } from "../apiRquest/apiRequest";
import urlParser from "js-video-url-parser";
import logoYoutube from '../imgs/logo youtube.png'
import SlickVideoSlider from "../components/slick_video_slider/skick_video_slider";


const pageTitle = 'Sumate a nuestros eventos en vivo';
const pageDesc = 'Vení a descubrir todos los Eventos en Vivo que ofrece Balanz, cursos, capacitaciones con especialistas y más.';

let text = `<span class='highlightsbold'>Muy pronto habrá un nuevo evento en vivo</span>`;
const defaultVideo = null;
const defaultTitle = 'Dólares';
const defaultDescription = 'Cada dólar es una oportunidad. Accedé a las mejores opciones de inversión en dólares.';

const urlEmbed = (video_url) => {
    var video = urlParser.parse(video_url);
    var urlObject = {format: 'embed'}
    urlObject.videoInfo = video;
    return urlParser.create(urlObject);
}
const LivePage = () => {
    const [link, setLink] = useState(defaultVideo)
    const [title, setTitle] = useState(defaultTitle)
    const [topic, setTopic] = useState(text)
    const [description, setDescription] = useState(defaultDescription)
    const [latest, setLatest] = useState([]);

    const slickSettings = {
        centerMode: false,
        centerPadding: "0px",
          initialSlide: 0,
          slidesToShow: 4,
        dots: false,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: { centerMode: false, slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 576,
                settings: {  centerMode: false, slidesToShow: 2, slidesToScroll: 2, infinite: false }
            }
          ]
    };


    useEffect(() => {
        apiRequest
        .get('/v1/events/live')
        .then(res => {
            if(null !== res.data) {
                setTitle(res.data.title)
                setTopic(res.data.topic)
                setDescription(res.data.description)
                urlEmbed(res.data.url_live)
                if(res.data.event_status === 2 && res.data.url_live !== null && res.data.url_live !== '') {
                    setLink(urlEmbed(res.data.url_live))
                }else if (res.data.event_status === 3 && res.data.url_end !== null && res.data.url_end !== ''){
                    setLink(urlEmbed(res.data.url_end))
                }
            }
        })
        .catch(err => {
            setLink(defaultVideo)
        })


        apiRequest
        .get("/v1/events_latest")
        .then((res) => {
            const data = [];
            res.data.data.forEach((element) => {
                data.push({
                    url: element.link,
                    title: element.title
                })
            })
            setLatest(data);
        })
        .catch((err) => {
            console.log(err);
        })
    }, [setLatest])


    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerLive"
                bannerTrasparecy="bannerMask"
                productTitle="Balanz Live"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={topic}
                bannerTextClassName="bannerTextInversiones"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <section className="fullwidth live-desc">
                {link !== null ? (
                    <Container>
                        <h2><span className="primary-color smaller-title live-title">{title}</span> </h2>
                        <Row className="justify-content-center pb-3 pb-sm-2 pb-md-3 pb-md-4 pb-lg-5">
                            <Col xs={12} className="d-flex">
                                <p className="base-text">{description}</p>
                            </Col>
                        </Row>
                        <Row className="text-center mt-3 mt-md-4 d-flex justify-content-center">
                            <Col xs={12}>
                                <IframeVIdeo
                                    src={link}
                                    mobile={false}
                                />
                            </Col>
                        </Row>

                    </Container>
                ): (
                    <Container className="no-evento-em-vivo text-center">
                        <div className="d-flex align-items-center">
                            <img src={logoYoutube} />
                            <h4>
                                Ingresá a nuestro canal de Youtube para volver a ver el último evento. <br/> Suscribite para no perderte ninguno.
                            </h4>
                        </div>
                        <Button variant="secondary" text="Accedé al canal" onClick={() => { window.open("https://www.youtube.com/channel/UCl7iljPb3wACm-lOyqM1dHA", "_blank") }} />
                    </Container>
                )}

            </section>
            {latest.length > 0 && (
                <section className="bg-lt-grey latest-events-live">
                    <Container>
                        <Row className="justify-content-center mt-5 py-5 g-3 g-md-3">
                            <h2 class="title">Últimos eventos</h2>
                            <SlickVideoSlider linkList={latest} slickSettings={slickSettings} />
                        </Row>
                    </Container>
                </section>
            )}

            <section className="fullwidthbg forma-segura">
                <Container className="d-flex align-items-stretch">
                    <Row className="d-flex justify-content-start align-items-center flex-grow-1 flex-shrink-1">
                        <Col xs={12} md={9}>
                            <div className="section-content">
                                <h2 className="titulo" ><span className="tit-60">Manejá tus inversiones de <br className="d-none d-md-inline" /><span className="highlightsbold">forma simple, segura y online</span></span></h2>
                            </div>
                            <div className="mt-3 mt-md-4 mt-lg-5">
                                <Button variant="secondary" text="Abrí tu cuenta" onClick={() => { window.open("/abrir-cuenta-2.aspx", "_blank") }} />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className="fullwidth suscribe">
                <Container>
                    <SuscribeTalks
                        ClassNameDisplay="section-content"
                        ClassNameInput="custom-email-input"
                        ButtonStyle="secondary"
                        IDCampaignAction={1056}
                    />
                </Container>
            </section>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"evento-en-vivo"}></Layout>
        </main>
    );
};

export default LivePage;